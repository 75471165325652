function SignOutPage() {
  return (
    <div>
      <h1>로그아웃</h1>
      <p>로그인 아웃되었습니다.</p>
    </div>
  );
}

export default SignOutPage;
